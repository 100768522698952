import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import HomeLogos from "../components/home-logos" für Seiten mit Mehr als einem Partner

const Wrapper = styled.div`
  margin: 100px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
  @media only screen and (max-width: ${props => props.theme.breakpoints.small}){
    margin: 0 10px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 449px;;
  margin: auto;
  flex-shrink: 0;

  .gatsby-image-wrapper {
    height: 445px;
    width: 445px;
    margin-bottom: 10px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.small}){
      height: initial;
      width: initial;
    }
  }
`

const TitleBlock = styled.div`
  margin-left: 64px;

  h1 {
    margin-bottom: 24px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
  }

  p {
    padding-bottom: 24px;
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin-top: 63px;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.smallest}) {
    h1 {
      font-size: 2.0rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const GraySpan = styled(Span)`
  background: ${({ theme }) => theme.palette.navCol};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.lightText};
  }
`

const BlackSpan = styled(GraySpan)`
  background: ${({ theme }) => theme.palette.darkText};
`

const BlankSpace = styled.div`
  flex: 1;
`

const TextBlock = styled.div`
  padding: 32px 108px;
  width: 640px;
  height: fit-content;
  align-self: center;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 32px;
    width: calc(100% - 32px);
  }
`

const SideImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
  }
`

const SideImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div {
    height: 100%;
  }
`

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      {
        man: file(name: { eq: "Service" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SektionalTore-Edit|ID_scene_Verglasung_242_9006_Nr3885|SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 675, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
          <Margins>
            <Flex direction={"column-reverse"}>
              <ImageWrapper maxWidth="280">
                <Img fluid={props.man.childImageSharp.fluid} alt="A builder" />
              </ImageWrapper>

              <TitleBlock>
                <h1>
                  Willkommen bei
                  <br />
                  SCHNEIDER TORE
                </h1>
                <p>
                  Unternehmensgründer Karl Schneider aus Adelzhausen hat das
                  Unternehmen aufgebaut, sein Sohn Michael Schneider führt es
                  heute erfolgreich fort. "Unsere jahrelange Erfahrung ist
                  unsere Stärke und Ihr Vorteil". Schneider Tore in Adelzhausen
                  bei Aichach, gelegen an der A8 und genau zwischen München und
                  Augsburg ist Ihr zuverlässiger Partner wenn es darum geht,
                  Ihre Garage oder dem Neubau ein Gesicht zu geben.
                  {/* Wir kommen zu Ihnen und */}
                </p>
              </TitleBlock>
            </Flex>
          </Margins>

          <BlackSpan>
            <Margins>
              <p>
                Die Firma Schneider Tore ist in der 2. Generation Ihr Partner{" "}
                <br />
                für Garagentore, Garagentüren und Tor-Antriebe.
              </p>
            </Margins>
          </BlackSpan>

          <Span>
            <Flex direction="column">
              <BlankSpace />
              <TextBlock>
                <h2>Geschichte</h2>
                <p>
                  Wir sorgen für Bewegung. Seit 1982.
                  <br />
                  <br />
                  Von diesem Grundsatz profitieren Häuslebauer und
                  -modernisierer, aber auch industrielle Kunden wie
                  Logistikunternehmen bereits in der der 2. Generation.
                   „Wer uns mit einem Sektional-
                  oder Schwingtor für seine Doppelgarage inklusive Antrieb
                  beauftragt, vertraut uns letztlich sein Haus an. Und damit
                  etwas sehr Privates. Da darf es bei Qualität und Sicherheit
                  keine Kompromisse geben.“ stellt Inhaber Michael Schneider klar.
                  <br />
                  <br />
                  Kein Wunder, dass bei so viel Sinn für Qualität die Wahl auf
                  die <a href="https://normstahl.com/" target="_blank" rel="noopener noreferrer">Firma Normstahl</a> als enger Partner von Schneider Tore
                  gefallen ist. Denn ganz gleich, ob es sich um Garagentore und
                  -türen, um einen singulären Antrieb oder um eine große
                  industrielle Lösung handelt: Das absolute Streben nach
                  kompromissloser Qualität – gepaart mit einem ganzheitlichen
                  Beratungsansatz, bei dem der individuelle Wunsch des Kunden im
                  Mittelpunkt des Handelns steht, das verbindet beide
                  Unternehmen.
                  <br />
                  <br />
                </p>
              </TextBlock>

              <SideImage>
                <SideImageWrapper>
                  <Img
                    fluid={props.allImageSharp.edges[4].node.fluid}
                    alt="Our offices"
                  />
                </SideImageWrapper>
                <SideImageWrapper>
                  <Img
                    fluid={props.allImageSharp.edges[6].node.fluid}
                    alt="Our offices"
                  />
                </SideImageWrapper>
              </SideImage>
            </Flex>
          </Span>
          {/*
          <HomeLogos style={{ margin: "32px 0" }} />

          <Span>
            <Flex direction="column">
              <SideImage>
                <SideImageWrapper>
                  <Img
                    fluid={props.allImageSharp.edges[0].node.fluid}
                    alt="Our happy team"
                  />
                </SideImageWrapper>
              </SideImage>

              <TextBlock>
                <h2>UNSER TEAM</h2>
                <p>
                  Bei Schneider Tore finden Sie nicht nur Industriemechaniker
                  und Elektroniker für Betriebstechnik, sondern auch Rollladen-
                  und Sonnenschutzmechatroniker, qualifizierte Schreiner und
                  Produktdesigner sowie Industriekaufleute. Kurz: Ein
                  erstklassiges Team, bei dem ein Rädchen perfekt ins andere
                  greift.
                  <br />
                  <br />
                  Mit dieser Vielfalt an Spezialisten und Generalisten sind wir
                  zugleich in der Lage, Ihnen von der Erstberatung bis zur
                  Endabnahme vor Ort die komplette Wertschöpfungskette für
                  Garagentore, -türe und Systeme anzubieten – ein unschätzbarer
                  Mehrwert gerade bei größeren Projekten, zum Beispiel im
                  Logistikbereich. So haben Sie als Kunde unabhängig von der
                  Größe des Auftrags und seiner Komplexität immer einen festen
                  Ansprechpartner, der sich über den kompletten Projektzyklus um
                  alle Ihre Anliegen und Rückfragen kümmert. Versprochen.
                  <br />
                  <br />
                </p>
              </TextBlock>

              <BlankSpace />
            </Flex>
          </Span>
          */}
          {/* <GraySpan pageBreak /> */}

          {/* <SingleReview /> */}
        </Wrapper>
      </Layout>
    )}
  />
)

export default IndexPage
